import React from "react";
import Footer from "src/components/Footer_uk";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          src="//tt.mbww.com/tt-b028e7b30ae6629575651e0369e15c482a1b97a7d437334f1b1cf00f5c06a78f.js"
          async
        />
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/uk/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a href="/uk/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/uk/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/allergy/">Allergy</a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">After sun</a>
                      </li>                      
                    </ul>
                  </li>
                  <li>
                    <a href="/uk/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a href="/uk/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                  <li className="nav-selected nav-path-selected">
                    <a href="/uk/where-to-buy/" target="_self">
                      Where to buy
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/where-to-buy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/">PT</a>
                      </li>
                      <li>
                        <a href="/it/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h1
                  style={{
                    "-webkit-text-align": "center",
                    "text-align": "center"
                  }}
                >
                  WHERE TO BUY
                </h1>
                <h3
                  style={{
                    "-webkit-text-align": "left",
                    "text-align": "left"
                  }}
                >
                  PIZ BUIN® products are available at the following retailers:
                </h3>
                <div className="ktsImageFloatingBlock">
                  <div className="imgWrapper">
                    <img
                      src="/assets/images/WTB_boots.jpg"
                      alt="BOOTS"
                      width="194"
                      height="194"
                    />
                  </div>
                  <div className="textWrapper">
                    <p>
                      Shop now at{" "}
                      <a
                        title="PIZ BUIN® products are available here"
                        href="http://www.boots.com/en/Piz-Buin"
                        target="_blank"
                      >
                        BOOTS
                      </a>
                    </p>
                  </div>
                </div>
                <div className="ktsImageFloatingBlock">
                  <div className="imgWrapper">
                    <img
                      src="/assets/images/WTB_tesco.jpg"
                      alt="TESCO"
                      width="194"
                      height="194"
                    />
                  </div>
                  <div className="textWrapper">
                    <p>
                      Shop now at{" "}
                      <a
                        title="PIZ BUIN® products are available here"
                        href="http://www.tesco.com/groceries/product/search/default.aspx?searchBox=piz+buin&sc_cmp=tescohp_sws-1_piz+buin"
                        target="_blank"
                      >
                        TESCO
                      </a>
                    </p>
                  </div>
                </div>
                <div className="ktsImageFloatingBlock">
                  <div className="imgWrapper">
                    <img
                      src="/assets/images/WTB_superdrug.jpg"
                      alt="SUPERDRUG"
                      width="194"
                      height="194"
                    />
                  </div>
                  <div className="textWrapper">
                    <p>
                      Shop now at{" "}
                      <a
                        title="PIZ BUIN® products are available here"
                        href="http://www.superdrug.com/search?q=piz+buin&searchsubmit=Searchn"
                        target="_blank"
                      >
                        SUPERDRUG
                      </a>
                    </p>
                  </div>
                </div>
                <div className="ktsImageFloatingBlock">
                  <div className="imgWrapper">
                    <img
                      src="/assets/images/WTB_sainsbury.jpg"
                      alt="SAINSBURY'S"
                      width="194"
                      height="194"
                    />
                  </div>
                  <div className="textWrapper">
                    <p>
                      Shop now at{" "}
                      <a
                        title="PIZ BUIN® products are available here"
                        href="https://www.sainsburys.co.uk/gol-ui/SearchDisplayView?filters[keyword]=piz%20buin"
                        target="_blank"
                      >
                        SAINSBURY'S
                      </a>
                    </p>
                  </div>
                </div>
                <div className="ktsImageFloatingBlock">
                  <div className="imgWrapper">
                    <img
                      src="/assets/images/WTB_asda.jpg"
                      alt="ASDA"
                      width="194"
                      height="194"
                    />
                  </div>
                  <div className="textWrapper">
                    <p>
                      Shop now at{" "}
                      <a
                        title="PIZ BUIN® products are available here"
                        href="https://groceries.asda.com/search/PIZ%20BUIN"
                        target="_blank"
                      >
                        ASDA
                      </a>
                    </p>
                  </div>
                </div>
                <div className="ktsImageFloatingBlock">
                  <div className="imgWrapper">
                    <img
                      src="/assets/images/WTB_ocado.jpg"
                      alt="OCADO"
                      width="194"
                      height="194"
                    />
                  </div>
                  <div className="textWrapper">
                    <p>
                      Shop now at{" "}
                      <a
                        title="PIZ BUIN® products are available here"
                        href="https://www.ocado.com/webshop/getSearchProducts.do?clearTabs=yes&isFreshSearch=true&entry=piz+buin"
                        target="_blank"
                      >
                        OCADO
                      </a>
                    </p>
                  </div>
                </div>
                <div className="ktsImageFloatingBlock">
                  <div className="imgWrapper">
                    <img
                      src="/assets/images/WTB_suncareshop_logo_UK.jpg"
                      alt="THE SUNCARE SHOP"
                      width="194"
                      height="194"
                    />
                  </div>
                  <div className="textWrapper">
                    <p>
                      Shop now at{" "}
                      <a
                        title="PIZ BUIN® products are available here"
                        href="https://www.thesuncareshop.com/?s=PIZ+BUIN&post_type=product&submit=Search"
                        target="_blank"
                      >
                        THE SUNCARE SHOP
                      </a>
                    </p>
                  </div>
                </div>
              <p> </p>
               
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.instagram.com/PizBuin_UK/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n/* <![CDATA[ */\nvar google_conversion_id = 924078965;\nvar google_custom_params = window.google_tag_params;\nvar google_remarketing_only = true;\n/* ]]> */\n"
          }}
        />
        <script
          type="text/javascript"
          src="//www.googleadservices.com/pagead/conversion.js"
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <div style="display:inline">\n            <img height="1" width="1" style="border-style:none" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/924078965/?value=0&amp;guid=ON&amp;script=0">\n         </div>\n      '
          }}
        />
      </div>
    );
  }
}

export default Page;
